const App = {
    SetBackground: () => {
        $('[setBackground]').each(function() {
            var background = $(this).attr('setBackground')
            $(this).css({
                "background-image": "url(" + background + ")",
                "background-size": "cover",
                "background-position": "center center"
            })
        })
        $('[setBackgroundRepeat]').each(function() {
            var background = $(this).attr('setBackgroundRepeat')
            $(this).css({
                "background-image": "url(" + background + ")",
                "background-repeat": "repeat"
            })
        })
    },
    equalHeightElement: el => {
        let height = 0;
        let thisHeight = 0;
        $(el).each(function() {
            thisHeight = $(this).height();
            if (thisHeight > height) {
                height = thisHeight;
            }
        });
        $(el).height(height)
    },
    initLazyLoad: () => {
        return new LazyLoad({
            elements_selector: ".lazy"
        });
    },
    ScrollTo: y => {
        $('html, body').animate({
            scrollTop: y
        }, 1000)
    },
    init: () => {
        App.SetBackground()
        App.initLazyLoad()
    }
}

function initMapping() {
    let MainList = new MappingListener({
            selector: '.twg-header .main-list',
            mobileWrapper: '.twg-header .mobile-wrap',
            mobileMethod: 'appendTo',
            desktopWrapper: '.twg-header .bottom-wrap',
            desktopMethod: 'prependTo',
            breakpoint: 1025
        }).watch()
        // let Language = new MappingListener({
        //     selector: '.twg-header .language-list',
        //     mobileWrapper: '.twg-header .mobile-button',
        //     mobileMethod: 'insertBefore',
        //     desktopWrapper: '.twg-header .bottom-wrap',
        //     desktopMethod: 'appendTo',
        //     breakpoint: 1025
        // }).watch()
    let MobileButton = new MappingListener({
        selector: 'header .mobile-button',
        mobileWrapper: '.twg-header .left-wrap',
        mobileMethod: 'prependTo',
        desktopWrapper: '.twg-header .bottom-wrap',
        desktopMethod: 'prependTo',
        breakpoint: 1025
    }).watch()
    let Search = new MappingListener({
        selector: '.twg-header .searchbox',
        mobileWrapper: '.twg-header .mobile-wrap',
        mobileMethod: 'appendTo',
        desktopWrapper: '.twg-header .top-wrap',
        desktopMethod: 'prependTo',
        breakpoint: 1025
    }).watch()
    let Hotline = new MappingListener({
        selector: '.twg-header .hotline-wrap',
        mobileWrapper: '.twg-header',
        mobileMethod: 'appendTo',
        desktopWrapper: '.twg-header .top-wrap',
        desktopMethod: 'appendTo',
        breakpoint: 1025
    }).watch()
}

function initSlider() {
    let homeBanner = new Swiper('.home-banner .swiper-container', {
        slidesPerView: 1,
        speed: 1000,
        autoplay: {
            delay: 4000
        }
    })
    let homeNews = new Swiper('.home-5 .swiper-container', {
        slidesPerView: 1,
        speed: 1000,
        autoplay: {
            delay: 4000
        },
        pagination: {
            el: '.home-5 .swiper-navigation',
            clickable: true
        }
    })
    let otherNews = new Swiper('.nghien-cuu-khoa-hoc-ct-2 .swiper-container', {
        slidesPerView: 3,
        speed: 1000,
        spaceBetween: 30,
        autoplay: {
            delay: 4000
        },
        breakpoints: {
            1025: {
                slidesPerView: 2
            },
            576: {
                slidesPerView: 1
            }
        },
        navigation: {
            nextEl: '.nghien-cuu-khoa-hoc-ct-2 .swiper-next',
            prevEl: '.nghien-cuu-khoa-hoc-ct-2 .swiper-prev'
        }
    })
}

function initHeight() {
    App.equalHeightElement('.chuyen-khoa-ct .other-service .list ul li')
}

function toggleMobileMenu() {
    let mButton = $('.twg-header .mobile-button')
    let mWrapper = $('.twg-header .mobile-wrap')
    mButton.on('click', function() {
        $(this).toggleClass('active')
        mWrapper.toggleClass('active')
    })
}

function mobileToggleDropdown() {
    $('header .main-list ul li em').on('click', function() {
        return $(this).siblings('.dropdown, .mega-wrap').is(':hidden') ? $(this).siblings('.dropdown, .mega-wrap').slideDown() : $(this).siblings('.dropdown, .mega-wrap').slideUp()
    })
}

function introduceTab() {
    $('.gioi-thieu-4 .content-wrap .nav-tab .item').on('click', function() {
        let itemId = $(this).attr('data-item')
        $('.gioi-thieu-4 .content-wrap .nav-tab .item').removeClass('active')
        $(this).addClass('active')
        $('.gioi-thieu-4 .content-wrap .nav-content .item').removeClass('active')
        $(`.gioi-thieu-4 .content-wrap .nav-content .item[data-content=${itemId}]`).addClass('active')
        console.log($(this).width() * parseInt(itemId - 1) - $(this).width() * 2)
        $('.gioi-thieu-4 .content-wrap .nav-tab').stop().animate({
            'scrollLeft': $(this).outerWidth() * parseInt(itemId) - $(this).outerWidth() * 2
        }, 500);
    })
    $('.gioi-thieu-4 .content-wrap .nav-tab .item:first-child').trigger('click')
}

function questionAccordion() {
    $('.question-list .item .heading').on('click', function() {
        if ($(this).siblings().is(':hidden')) {
            $(this).siblings().slideDown()
            $(this).parent().addClass('active')
        } else {
            $(this).siblings().slideUp()
            $(this).parent().removeClass('active')
        }
    })
}

function tutorialAccordion() {
    $('.huong-dan-1 .tutorial-accordion .item .button').on('click', function() {
        if ($(this).siblings('.text').find('.full-content').is(':hidden')) {
            $(this).siblings('.text').find('.full-content').slideDown()
            $(this).parent().addClass('active')
        } else {
            $(this).siblings('.text').find('.full-content').slideUp()
            $(this).parent().removeClass('active')
        }
    })
}

function initNavigation() {
    $('.main-navigation .heading').on('click', function() {
        $(this).toggleClass('active')
        $(this).siblings().slideToggle()
    })
}

function specialNews() {
    let nomalHeight = $('.tin-tuc-3 .special-news-item:not(.big-item)').height()
    let bigItemEl = $('.tin-tuc-3 .special-news-item.big-item')
    return $(window).width() > 1025 ? bigItemEl.height(nomalHeight * 2 + 30) : bigItemEl.removeAttr('style')
}

function rescruitmentPopup() {

    
    $('.tuyen-dung-ct-1 .button-list a.apply').on('click', function() {
        $('.chuyen-khoa-ct .popup-wrapper').addClass('active')
    })
    $('.chuyen-khoa-ct .popup-wrapper .close-button').on('click', function() {
        $(this).parents('.popup-wrapper').removeClass('active')
    })
}
$(document).ready(function() {
    Headers()
    initMapping()
    initSlider()
    initHeight()
    initNavigation()
    toggleMobileMenu()
    introduceTab()
    questionAccordion()
    tutorialAccordion()
    specialNews()
    mobileToggleDropdown()
    rescruitmentPopup()
    App.init()
    setInterval(function(){ 
        $('.popup-wrapper').removeAttr('id').removeAttr('style')
    }, 0);
})
$(window).resize(function() {
    specialNews()
})
function Headers() {
	var e = 0;
	$(window).scroll(function () {
		var i = $(window).scrollTop();
		$("body header").toggleClass("hide", i > e), e = $(window).scrollTop(), i > 0 ? $("header").addClass("is-scrolled") : $("header").removeClass("is-scrolled"), $(window).scrollTop() >= $(document).height() - $(window).height()
	})
}